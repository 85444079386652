import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
ReactGA.initialize("your GA measurement id");

export const Header = (props) => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

//   <!-- Event snippet for Page view conversion page
// In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->
// <script>
// function gtag_report_conversion(url) {
//   var callback = function () {
//     if (typeof(url) != 'undefined') {
//       window.location = url;
//     }
//   };
//   gtag('event', 'conversion', {
//       'send_to': 'AW-16463576701/9KOfCKqvq5MZEP38uKo9',
//       'event_callback': callback
//   });
//   return false;
// }
// </script>


  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              { matches ? <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="tel:+13605362634"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Call Us
                </a>{" "}
                <a
                  href="sms:+13605362634"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Text Us
                </a>{" "}
              </div> : null }
              { !matches ? <div className="col-md-8 col-md-offset-2" style={{paddingTop: 150}}>
                <h2>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h2>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                    style={{marginBottom: 10, marginRight: 5, textAlign: 'center', display: 'block'}}
                    href="https://wa.me/13605362634?text=I'm%20interested%20in%20your%20cleaning%20services"
                  >
                    <img src="img/WhatsAppButtonGreenMedium.svg" alt="Whatsapp"/>
                  </a>{" "}
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 100}}>
                  <a
                    style={{marginBottom: 10, marginRight: 5}}
                    href="tel:+13605362634"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Call Us
                  </a>{" "}
                  <a
                    style={{marginBottom: 10, marginLeft: 5}}
                    href="sms:+13605362634"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Text Us
                  </a>{" "}
                </div>
              </div>: null}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
